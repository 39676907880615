import ProfilePicCard from '../components/ProfilePicCard'
import VirtualCardLink from '../components/VirtualCardLink'

function Profile(props) {
	
let mobileNumDisplay
let workNumDisplay 
	let linkedInDisplay;
	let linkedInLink;
	let instagramDisplay;
	let instagramLink;

  if (props.person.mobileNumber) {
		mobileNumDisplay = dotFormatNum(props.person.mobileNumber);
	}
	if (props.person.workNumber) {
		workNumDisplay = dotFormatNum(props.person.workNumber);
	}

	if (props.person.linkedIn && !props.person.linkedInDisplay) {
		linkedInDisplay = props.person.linkedIn
			.replace('https://www.linkedin.com/in/', '')
			.slice(0, -1);
	} else {
		linkedInDisplay = props.person.linkedInDisplay;
	}
	if (!props.person.linkedIn && props.person.linkedInDisplay) {
		linkedInLink = 'https://www.linkedin.com/in/' + linkedInDisplay;
	} else {
		linkedInLink = props.person.linkedIn;
	}

  if (props.person.instagram && !props.person.instagramDisplay) {
		instagramDisplay =
			'@' +
			props.person.instagram
				.replace('https://www.instagram.com/', '')
				.slice(0, -1);
	} else if (props.person.instagramDisplay) {
		instagramDisplay = props.person.instagramDisplay;
	}

	if (!props.person.instagram && props.person.instagramDisplay) {
		instagramLink =
			'https://www.instagram.com/' + instagramDisplay.replace('@', '');
	} else if (props.person.instagram) {
		instagramLink = props.person.instagram;
	}

  const contactUrl = document.URL.replace('.html', '');

	function dotFormatNum(phoneNum) {
		let phoneNumString = phoneNum.toString();
		const phoneNumArr = phoneNumString.split('');
		phoneNumArr.splice(3, 0, '.');
		phoneNumArr.splice(7, 0, '.');
		phoneNumString = phoneNumArr.join('');
		return phoneNumString;
	}

	document.title =
		props.person.firstName + ' ' + props.person.lastName + ' | AMFM Inc.';

  return (
		<section id='profile'>
			<ProfilePicCard
				profilePic={props.person.profilePic}
				firstName={props.person.firstName}
			/>
			<section id='profile-name-title'>
				<h1
					id='profile-name'
					data-firstname={props.person.firstName}
					data-lastname={props.person.lastName}>
					{props.person.firstName} {props.person.lastName}
				</h1>
				<h4 id='profile-title'>{props.person.jobTitle}</h4>
			</section>
			<section id='profile-links'>
				<a
					id='directory-link'
					href='/'>
					Staff Directory
				</a>
				<ul>
					<li>
						<VirtualCardLink person={props.person} />
					</li>
					<li>
						<a
							id='profile-link-email'
							href={'mailto:' + props.person.email}>
							<i className='fa-solid fa-envelope'></i>
							<span>{props.person.email}</span>
						</a>
					</li>
					{props.person.mobileNumber && (
						<li>
							<a
								id='profile-link-mobile'
								href={'tel:' + props.person.mobileNumber}>
								<i className='fa-solid fa-mobile-screen-button'></i>
								<span>{mobileNumDisplay}</span>
							</a>
						</li>
					)}
					<li>
						<a
							id='profile-link-work-phone'
							href={'tel:' + props.person.workNumber}>
							<i className='fa-solid fa-phone'></i>
							<span>{workNumDisplay}</span>
						</a>
					</li>

					{instagramLink && (
						<li>
							<a
								href={instagramLink}
								id='profile-link-social-instagram'>
								<i className='fa-brands fa-instagram'></i>
								<span>{instagramDisplay}</span>
							</a>
						</li>
					)}
					{linkedInLink && (
						<li>
							<a
								href={linkedInLink}
								id='profile-link-social-instagram'>
								<i className='fa-brands fa-linkedin'></i>
								<span>{linkedInDisplay}</span>
							</a>
						</li>
					)}
				</ul>
			</section>
		</section>
	);
}

export default Profile;