import {BrowserRouter,Routes, Route, Outlet} from 'react-router-dom';
import React, {useState,useEffect} from 'react';

import Header from './components/Header'
import Footer from './components/Footer'
// import staffList from "../data/staffList";

import Home from './pages/Home'
import Profile from './pages/Profile'





function App() {
	const [staffList, setStaffList] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(
					'https://opensheet.elk.sh/1ZcoSdOYoaJAMbfjOgbHJXbc7-1MOeRgXtkYhGFiHkYg/siteData',
				);
				if (!response.ok) {
					throw new Error('Failed to fetch staff list');
				}
				const data = await response.json();
				console.log(data);
				setStaffList(data);
			} catch (error) {
				console.error('Error fetching staff list: ', error);
			}
		};

		fetchData();
	}, []);

	// const getStaffList = () => {
	// 	fetch('../data/staffList.json')
	// 		.then((response) => {
	// 			return response.json();
	// 		})
	// 		.then((staffListJson) => {
	// 			setStaffList(staffListJson);
	// 		});
	// };

	// useEffect(() => {
	// 	getStaffList();
	// }, []);

	const Layout = () => {
		return (
			<>
				<Header />
				<Outlet />
				<Footer />
			</>
		);
	};
	const directoryRoutes = staffList.map((person) => {
		return (
			<Route
				key={person.key}
				// path={person.firstName.toLowerCase()}
				path={person.email.replace('@amfm.ca', '')}
				element={<Profile person={person} />}
			/>
		);
	});

	return (
		<div className='App'>
			<BrowserRouter>
				<Routes>
					<Route
						path='/'
						element={<Layout />}>
						<Route
							path='/'
							element={<Home />}
						/>
						<Route
							path='profile'
							element={<Profile />}
						/>
						{directoryRoutes}
					</Route>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;